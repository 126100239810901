/**
 * LanguageMenu.js - Language menu component.
 *
 * @author Alex Symeonidis
 */
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

const languages = [
  { key: 'el', value: 'el', flag: 'gr', text: 'Ελληνικά' },
  { key: 'en', value: 'en', flag: 'gb', text: 'English' }
]

const LanguageMenu = () => {
  const { i18n } = useTranslation();

  const [values, setValues] = useState({
    language: i18n.language
  });

  function handleChange(event, {name, value}) {
    i18n.changeLanguage(value)
      .then(language => {
        setValues({
          ...values,
          [name]: value
        });
      });
  }

  return (
    <Dropdown name={"language"}
              options={languages}
              selection
              value={i18n.language}
              onChange={handleChange}/>
  )
}

export default LanguageMenu;