/**
 * new-volunteer-capture-form.js - New volunteer capture form.
 *
 * @author Alex Symeonidis
 */
import React, { useEffect, useState } from "react"

import { Button, Form, FormCheck, FormControl, FormGroup, FormText, Spinner } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react'

import { Formik } from 'formik';
import { newVolunteerCaptureFormSchema } from "./validation-rules";
import Dropzone from "react-dropzone";

import { useTranslation } from "react-i18next";

const linesOfEducation = [
  { key: '942a93a8-fedd-11ea-adc1-0242ac120002', text: 'Βιολογία', value: '942a93a8-fedd-11ea-adc1-0242ac120002' },
  { key: '942a9600-fedd-11ea-adc1-0242ac120002', text: 'Χημεία', value: '942a9600-fedd-11ea-adc1-0242ac120002' },
  { key: '942a96f0-fedd-11ea-adc1-0242ac120002', text: 'Πληροφορική', value: '942a96f0-fedd-11ea-adc1-0242ac120002' },
  { key: '942a97c2-fedd-11ea-adc1-0242ac120002', text: 'Μαθηματικά', value: '942a97c2-fedd-11ea-adc1-0242ac120002' },
  { key: '942a9a88-fedd-11ea-adc1-0242ac120002', text: 'Μηχανική', value: '942a9a88-fedd-11ea-adc1-0242ac120002' },
  { key: '942a9b64-fedd-11ea-adc1-0242ac120002', text: 'Φυσική', value: '942a9b64-fedd-11ea-adc1-0242ac120002' },
  { key: '942a9cea-fedd-11ea-adc1-0242ac120002', text: 'Ιατρική', value: '942a9cea-fedd-11ea-adc1-0242ac120002' },
  { key: '00000000-0000-0000-0000-000000000000', text: 'Άλλο', value: '00000000-0000-0000-0000-000000000000' }
];

const levelsOfEducation = [
  { key: 'BACHELOR', text: 'Προπτυχιακές Σπουδές', value: 'BACHELOR' },
  { key: 'MASTERS', text: 'Μεταπτυχιακές Σπουδές', value: 'MASTERS' },
  { key: 'PHD', text: 'Διδακτορικές Σπουδές', value: 'PHD' }
]

const referrerTypes = [
  { key: 'FACEBOOK', text: 'Facebook', value: 'FACEBOOK'},
  { key: 'PARTNERS', text: 'Συνεργάτες', value: 'PARTNERS'},
  { key: 'FRIENDS', text: 'Φίλους', value: 'FRIENDS'},
  { key: 'WEBSITE', text: 'Ιστότοπο', value: 'WEBSITE'},
  { key: 'OTHER', text: 'Άλλο', value: 'OTHER'}
]

const NewVolunteerCaptureForm = (props) => {

  const { t } = useTranslation("new-volunteer-capture");

  const [state, setState] = useState({
    error: undefined,
    photos: [],
    resumeFiles: []
  });

  const photoThumbs = state.photos.map(file => {
    return (
      <div className={"file-thumbnail"} key={file.name}>
        <div className={"file-thumbnail-inner"}>
          <img
            src={file.preview}
            className={"file-thumbnail-image"}
            alt={'File ' + file.name}
          />
        </div>
      </div>
    )});

  const onPhotoDrop = (acceptedFiles) => {
    setState({
      ...state,
      photos: acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    });
  }

  const onResumeDrop = (acceptedFiles) => {
    setState({
      ...state,
      resumeFiles: acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    });
  }

  const translateError = (errorCode, values) => {
    if (errorCode !== undefined) {
      return t('new-volunteer-capture.error.' + errorCode, values);
    } else {
      return '';
    }
  }

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    state.photos.forEach(file => URL.revokeObjectURL(file.preview));
    state.resumeFiles.forEach(file => URL.revokeObjectURL(file.preview));
  });

  const onFormSubmit = (values, { setSubmitting }) => {
    let bodyData = {
      firstName: values.firstName,
      lastName: values.lastName,
      city: values.city,
      country: values.country,

      email: values.email,
      password: values.password,

      lineOfEducationId: values.lineOfEducationId,
      otherLineOfEducation: values.otherLineOfEducation,
      educationLevel: values.educationLevel,
      employer: values.employer,

      selfDescription: values.selfDescription,
      careerReasons: values.careerReasons,
      joiningReasons: values.joiningReasons,
      canPresentOnline: values.canPresentOnline,
      canPresentInPerson: values.canPresentInPerson,

      referralChannel: values.referralChannel,
      otherReferrer: values.otherReferrer
    }

    // Build a multi-part POST request.
    let data = new FormData();
    data.append('photo', state.photos[0]);
    if (state.resumeFiles.length > 0) {
      // Resume is optional. Add it only when it exists.
      data.append('resume', state.resumeFiles[0]);
    }
    data.append("body",JSON.stringify(bodyData));

    fetch(`${process.env.GATSBY_API_URL}/v1/form/new-volunteer`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "type": "formData"
      },
      body: data
    })
    .then(function (res) {
      setSubmitting(false);

      if (res.ok) {
        setState({
          ...state,
          error: undefined
        });

        // Mark this form as successfully submitted
        props.onSubmitCompleted();
      } else {
        setState({
          ...state,
          error: translateError("network.server-request-failed", {"status": res.status})
        });
      }
    })
    .catch(e => {
      setSubmitting(false);

      let errorReason;
      if (e.response === undefined) {
        errorReason = "Connection failed";
      } else {
        errorReason = e.response;
      }

      setState({
        ...state,
        error: translateError("network.server-connection-failed", {"reason": errorReason})
      });
    });
  };

  return <div>
    <div className="p-5 form-container" id="view-login-account">
      <div className="text-center">
        <h1 className="h4 text-gray-900 mb-4">{t('new-volunteer-capture.title')}</h1>
      </div>

      <Formik onSubmit={onFormSubmit}
              validationSchema={newVolunteerCaptureFormSchema}
              initialValues={{
                canPresentOnline: false,
                canPresentInPerson: false,
                photos: [],
                resumeFiles: []
              }}>
        {( {values, errors, touched,
             handleChange, handleBlur, handleSubmit,
             isSubmitting, setFieldValue }) => (
        <Form>

          {/* ---- Personal Information ---- */}
          <div className={"section-divider"}>{t('new-volunteer-capture.personal-information')}</div>

          <Form.Row>
            <FormGroup controlId="firstName" className="col-sm-6 mb-3 mb-sm-0">
              <Form.Label>{t('new-volunteer-capture.firstName')}</Form.Label>
              <FormControl type="text" name="firstName"
                           className="form-control-user"
                           value={values.firstName || ''}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           isInvalid={touched.firstName && errors.firstName}
                           placeholder={t('new-volunteer-capture.firstName-placeholder')} autoFocus/>
              <Form.Control.Feedback type="invalid">{translateError(errors.firstName)}</Form.Control.Feedback>
            </FormGroup>
            <FormGroup controlId="lastName" className="col-sm-6">
              <Form.Label>{t('new-volunteer-capture.lastName')}</Form.Label>
              <FormControl type="text" name="lastName"
                           className="form-control-user"
                           value={values.lastName || ''}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           isInvalid={touched.lastName && errors.lastName}
                           placeholder={t('new-volunteer-capture.lastName-placeholder')}/>
              <Form.Control.Feedback type="invalid">{translateError(errors.lastName)}</Form.Control.Feedback>
            </FormGroup>
          </Form.Row>

          <Form.Row>
            <FormGroup controlId="city" className="col-sm-6 mb-3 mb-sm-0">
              <Form.Label>{t('new-volunteer-capture.city')}</Form.Label>
              <FormControl type="text" name="city"
                           className="form-control-user"
                           value={values.city || ''}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           isInvalid={touched.city && errors.city}
                           placeholder={t('new-volunteer-capture.city-placeholder')}/>
              <Form.Control.Feedback type="invalid">{translateError(errors.city)}</Form.Control.Feedback>
            </FormGroup>
            <FormGroup controlId="country" className="col-sm-6">
              <Form.Label>{t('new-volunteer-capture.country')}</Form.Label>
              <FormControl type="text" name="country"
                           className="form-control-user"
                           value={values.country || ''}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           isInvalid={touched.country && errors.country}
                           placeholder={t('new-volunteer-capture.country-placeholder')}/>
              <Form.Control.Feedback type="invalid">{translateError(errors.country)}</Form.Control.Feedback>
            </FormGroup>
          </Form.Row>

          {/* ---- Account Information ---- */}

          <div className={"section-divider"}>{t('new-volunteer-capture.account-information')}</div>

          <Form.Row>
            <FormGroup controlId="email" className="col-sm mb-3">
              <Form.Label>{t('new-volunteer-capture.email')}</Form.Label>
              <FormControl type="email" name="email"
                           className="form-control-user"
                           value={values.email || ''}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           isInvalid={touched.email && errors.email}
                           placeholder={t('new-volunteer-capture.email-placeholder')}/>
              <Form.Control.Feedback type="invalid">{translateError(errors.email)}</Form.Control.Feedback>
              <FormText className="text-center text-muted">{t('new-volunteer-capture.email-note')}</FormText>
            </FormGroup>
          </Form.Row>

          <Form.Row>
            <FormGroup controlId="password" className="col-sm-6 mb-3 mb-sm-0">
              <Form.Label>{t('new-volunteer-capture.password')}</Form.Label>
              <FormControl type="password" name="password"
                           className="form-control-user"
                           value={values.password || ''}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           isInvalid={touched.password && errors.password}
                           placeholder={t('new-volunteer-capture.password-placeholder')}/>
              <Form.Control.Feedback type="invalid">{translateError(errors.password)}</Form.Control.Feedback>
            </FormGroup>
            <FormGroup controlId="password2" className="col-sm-6">
              <Form.Label>{t('new-volunteer-capture.password-repeat')}</Form.Label>
              <FormControl type="password" name="password2"
                           className="form-control-user"
                           value={values.password2 || ''}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           isInvalid={touched.password2 && errors.password2}
                           placeholder={t('new-volunteer-capture.password-repeat-placeholder')}/>
              <Form.Control.Feedback type="invalid">{translateError(errors.password2)}</Form.Control.Feedback>
            </FormGroup>
          </Form.Row>

          {/* ---- Education & Employment Information ---- */}

          <div className={"section-divider"}>{t('new-volunteer-capture.education-information')}</div>

          <Form.Row>
            <FormGroup controlId="lineOfEducationId" className="col-sm mb-3">
              <Form.Label>{t('new-volunteer-capture.line-of-education')}</Form.Label>
              <Dropdown
                placeholder={t('new-volunteer-capture.line-of-education-placeholder')}
                fluid
                search
                selection
                options={linesOfEducation}
                onChange={(event, data) => setFieldValue('lineOfEducationId', data.value)}
              />
              <Form.Control.Feedback type="invalid">{translateError(errors.lineOfEducationId)}</Form.Control.Feedback>
            </FormGroup>
          </Form.Row>
          {
            values.lineOfEducationId === "00000000-0000-0000-0000-000000000000" &&
            <Form.Row>
              <FormGroup controlId="otherLineOfEducation" className="col-sm mb-3">
                <FormControl type="text" name="otherLineOfEducation"
                             className="form-control-user"
                             value={values.otherLineOfEducation || ''}
                             onChange={handleChange} onBlur={handleBlur}
                             isInvalid={touched.otherLineOfEducation && errors.otherLineOfEducation}
                             placeholder={t('new-volunteer-capture.line-of-education-other-placeholder')}/>
                <Form.Control.Feedback type="invalid">{translateError(errors.otherLineOfEducation)}</Form.Control.Feedback>
              </FormGroup>
            </Form.Row>
          }

          <Form.Row>
            <FormGroup controlId="educationLevel" className="col-sm mb-3">
              <Form.Label>{t('new-volunteer-capture.level-of-education')}</Form.Label>
              <Dropdown
                placeholder={t('new-volunteer-capture.level-of-education-placeholder')}
                fluid
                search
                selection
                options={levelsOfEducation}
                value={values.educationLevel}
                onChange={(event, data) => setFieldValue('educationLevel', data.value)}
              />
              <Form.Control.Feedback type="invalid">{translateError(errors.educationLevel)}</Form.Control.Feedback>
            </FormGroup>
          </Form.Row>

          <Form.Row>
            <FormGroup controlId="employer" className="col-sm mb-3">
              <Form.Label>{t('new-volunteer-capture.employer')}</Form.Label>
              <FormControl type="text" name="employer"
                           className="form-control-user"
                           value={values.employer || ''}
                           onChange={handleChange} onBlur={handleBlur}
                           isInvalid={touched.employer && errors.employer}
                           placeholder={t('new-volunteer-capture.employer-placeholder')}/>
              <Form.Control.Feedback type="invalid">{translateError(errors.employer)}</Form.Control.Feedback>
            </FormGroup>
          </Form.Row>

          {/* ---- Personal Description ---- */}

          <div className={"section-divider"}>{t('new-volunteer-capture.personal-profile')}</div>

          <Form.Row>
            <FormGroup controlId="selfDescription" className="col-sm mb-3">
              <Form.Label>{t('new-volunteer-capture.self-description')}</Form.Label>
              <FormControl type="text" as={"textarea"} rows="5" name="selfDescription"
                           className="form-control-user"
                           value={values.selfDescription || ''}
                           onChange={handleChange} onBlur={handleBlur}
                           isInvalid={touched.selfDescription && errors.selfDescription}
                           placeholder={t('new-volunteer-capture.self-description-placeholder')}/>
              <Form.Control.Feedback type="invalid">{translateError(errors.selfDescription)}</Form.Control.Feedback>
            </FormGroup>
          </Form.Row>

          <Form.Row>
            <FormGroup controlId="careerReasons" className="col-sm mb-3">
              <Form.Label>{t('new-volunteer-capture.career-reasons')}</Form.Label>
              <FormControl type="text" as={"textarea"} rows="5" name="careerReasons"
                           className="form-control-user"
                           value={values.careerReasons || ''}
                           onChange={handleChange} onBlur={handleBlur}
                           isInvalid={touched.careerReasons && errors.careerReasons}
                           placeholder={t('new-volunteer-capture.career-reasons-placeholder')}/>
              <Form.Control.Feedback type="invalid">{translateError(errors.careerReasons)}</Form.Control.Feedback>
            </FormGroup>
          </Form.Row>

          <Form.Row>
            <FormGroup controlId="joiningReasons" className="col-sm mb-3">
              <Form.Label>{t('new-volunteer-capture.joining-reasons')}</Form.Label>
              <FormControl type="text" as={"textarea"} rows="5" name="joiningReasons"
                           className="form-control-user"
                           value={values.joiningReasons || ''}
                           onChange={handleChange} onBlur={handleBlur}
                           isInvalid={touched.joiningReasons && errors.joiningReasons}
                           placeholder={t('new-volunteer-capture.joining-reasons-placeholder')}/>
              <Form.Control.Feedback type="invalid">{translateError(errors.joiningReasons)}</Form.Control.Feedback>
            </FormGroup>
          </Form.Row>

          <Form.Row>
            <FormGroup controlId={"canPresentOnline"} className="col-sm mb-3">
              <FormCheck.Label>{t('new-volunteer-capture.presentation-style')}</FormCheck.Label>
              <FormCheck>
                <FormCheck.Input id={"canPresentInPerson"}
                                 checked={values.canPresentInPerson}
                                 isInvalid={touched.canPresentInPerson && errors.canPresentInPerson}
                                 onChange={handleChange} onBlur={handleBlur}/>
                <FormCheck.Label>{t('new-volunteer-capture.presentation-style-in-person')}</FormCheck.Label>
                <Form.Control.Feedback type="invalid">{translateError(errors.canPresentInPerson)}</Form.Control.Feedback>
              </FormCheck>
              <FormCheck>
                <FormCheck.Input id={"canPresentOnline"}
                                 checked={values.canPresentOnline}
                                 isInvalid={touched.canPresentOnline && errors.canPresentOnline}
                                 onChange={handleChange} onBlur={handleBlur}/>
                <FormCheck.Label>{t('new-volunteer-capture.presentation-style-online')}</FormCheck.Label>
                <Form.Control.Feedback type="invalid">{translateError(errors.canPresentOnline)}</Form.Control.Feedback>
              </FormCheck>
            </FormGroup>
          </Form.Row>

          {/* ---- Additional Information ---- */}

          <div className={"section-divider"}>{t('new-volunteer-capture.additional-information')}</div>

          <Form.Row>
            <FormGroup controlId="referralChannel" className="col-sm mb-3">
              <Form.Label>{t('new-volunteer-capture.referral-channel')}</Form.Label>
              <Dropdown
                placeholder={t('new-volunteer-capture.referral-channel-placeholder')}
                fluid
                search
                selection
                options={referrerTypes}
                onChange={(event, data) => setFieldValue('referralChannel', data.value)}
              />
              <Form.Control.Feedback type="invalid">{translateError(errors.referrer)}</Form.Control.Feedback>
            </FormGroup>
          </Form.Row>
          {
            values.referralChannel === 'OTHER' &&
            <Form.Row>
              <FormGroup controlId="otherReferrer" className="col-sm mb-3">
                <FormControl type="text" name="otherReferrer"
                             className="form-control-user"
                             value={values.otherReferrer || ''}
                             onChange={handleChange} onBlur={handleBlur}
                             isInvalid={touched.otherReferrer && errors.otherReferrer}
                             placeholder={t('new-volunteer-capture.referral-channel-other-placeholder')}/>
              <Form.Control.Feedback type="invalid">{translateError(errors.otherReferrer)}</Form.Control.Feedback>
              </FormGroup>
            </Form.Row>
          }

          {/* ---- Additional Files ---- */}

          <div className={"section-divider"}>{t('new-volunteer-capture.additional-resources')}</div>

          <Form.Row>
            <FormGroup className="col-sm mb-3">
              <Form.Label>{t('new-volunteer-capture.photo')}</Form.Label>
              <div className={"thumbsContainer"}>
                {photoThumbs}
              </div>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  setFieldValue('photos', acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)})));
                  onPhotoDrop(acceptedFiles);
                }}
                accept="image/*">
                {({getRootProps, getInputProps}) => {
                  return (
                  <div {...getRootProps({
                      className: "col dropzone" + (errors.photos && touched.photos ? " is-invalid" : ""),
                      onFocus: () => {touched.photos = true} })}>
                    <label htmlFor={"photo"}>Σύρε και ρήξε το αρχείο εδώ, ή κάνε κλικ για να το επιλέξεις.</label>
                    <input {...getInputProps()} id={"photo"}/>
                  </div>
                )}}
              </Dropzone>
              <Form.Control.Feedback type="invalid">{translateError(errors.photos)}</Form.Control.Feedback>
            </FormGroup>
          </Form.Row>

          <Form.Row>
            <FormGroup className="col-sm mb-3">
              <Form.Label htmlFor="resumeFiles">{t('new-volunteer-capture.resume')}</Form.Label>
              <ul>
              {
                state.resumeFiles.map(file => {
                  return (
                    <li key={file.path}>
                      {file.path} - {file.size} bytes
                    </li>
                  )
                })
              }
              </ul>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  setFieldValue('resumeFiles', acceptedFiles.map(file => Object.assign(file)));
                  onResumeDrop(acceptedFiles);
                }}
                accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf">
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps({
                      className: "col dropzone" + (errors.resumeFiles && touched.resumeFiles ? " is-invalid" : ""),
                      onFocus: () => {touched.resumeFiles = true} })}>
                    <label htmlFor={"resumeFile"}>Σύρε και ρήξε το αρχείο εδώ, ή κάνε κλικ για να το επιλέξεις.</label>
                    <input {...getInputProps()} id={"resumeFile"} />
                  </div>
                )}
              </Dropzone>
              <Form.Control.Feedback type="invalid">{translateError(errors.resumeFiles)}</Form.Control.Feedback>
            </FormGroup>
          </Form.Row>

          {
            isSubmitting &&
            <Spinner animation="border" variant="primary"/>
          }
          {
            state.error !== undefined &&
            <div className={"form-error"}>{state.error}</div>
          }
          {
            !isSubmitting &&
            <Button variant="primary" type="submit" id="form-submit" className="btn-user"
                    block disabled={isSubmitting} onClick={handleSubmit}>{t('new-volunteer-capture.submit')}</Button>
          }
        </Form>
        )}
      </Formik>

    </div>
  </div>

  /* eslint jsx-a11y/no-autofocus: off */
}

export default NewVolunteerCaptureForm;