/*
 * validation-rules.js - Validation rules for new volunteer capture form.
 *
 * @author Alex Symeonidis
 */

import * as yup from "yup";

/**
 * Maximum file size of files attached to the form. This is the total size
 * as a sum of all files attached in a certain category (field).
 *
 * @type {number}
 */
const MAX_FILE_SIZE = 1024 * 1024 * 5; // 5MB

/**
 * Creates a new rule that tests that the value of a field is equal to the
 * value of the referenced field.
 *
 * @param ref the referenced field.
 * @param msg the error message to produce when test fails.
 * @returns {*}
 */
function equalTo(ref, msg) {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path
    },
    test: function(value) {
      return value === this.resolve(ref);
    }
  });
}
yup.addMethod(yup.string, 'equalTo', equalTo);

/**
 * Tests that the given files do not exceed the given permissible file size.
 *
 * @param files the list of files to test
 * @param permissibleSize the total permissible file size for all files together.
 * @returns true, if total size is within permissible range.
 */
function isFileSetWithinPermissibleSize(files, permissibleSize) {
  let totalImageSetSize =
    files.map(file => file.size).reduce(function(a, b) { return a + b; }, 0);
  return totalImageSetSize <= permissibleSize;
}

/**
 * New volunteer capture form validation schema.
 */
export const newVolunteerCaptureFormSchema = yup.object({

  // ---- Personal Information ----

  firstName: yup.string().required('firstName.required'),
  lastName: yup.string().required('lastName.required'),
  city: yup.string().required('city.required'),
  country: yup.string().required('country.required'),

  // ---- Account Information ----

  email: yup.string()
    .required('email.required')
    .email('email.invalid-format'),

  // Rule: Is required and has at least 8 characters of which 1 is uppercase,
  //       1 lowercase, 1 number and 1 special character.
  password: yup.string()
    .required('password.required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "password.invalid-format"
    ),

  // Rule: Is required and password and password2 match
  password2: yup.string()
    .required('password-repeat.required')
    .equalTo(yup.ref('password'), 'password-repeat.not-matching'),

  // ---- Education & Employment Information ----

  lineOfEducationId: yup.string()
    .required('line-of-education.required'),

  // Rule: Is required only when line of education is "Other".
  otherLineOfEducation: yup.string()
    .notRequired()
    .when('lineOfEducation', {
      is: (val) => val === '00000000-0000-0000-0000-000000000000',
      then: yup.string().required('line-of-education-other.required'),
      otherwise: yup.string().notRequired()
    }),

  educationLevel: yup.string().required('level-of-education.required'),
  employer: yup.string().notRequired(),

  // ---- Personal Description ----

  selfDescription: yup.string().required('self-description.required'),
  careerReasons: yup.string().required('career-reasons.required'),
  joiningReasons: yup.string().required('joining-reasons.required'),
  // Rule: One or both in-person and online must be selected
  canPresentInPerson: yup.bool()
    .test("at-least-one-presentation", "presentation-style-in-person.one-of", function (value) {
      const { canPresentInPerson, canPresentOnline } = this.parent;
      return !(canPresentInPerson === false && canPresentOnline === false);
    }),
  // Rule: One or both in-person and online must be selected
  canPresentOnline: yup.bool()
    .test("at-least-one-presentation", "presentation-style-online.one-of", function (value) {
      const { canPresentInPerson, canPresentOnline } = this.parent;
      return !(canPresentInPerson === false && canPresentOnline === false);
    }),

  // ---- Additional Information ----

  referralChannel: yup.string().required('referral-channel.required'),

  // Rule: Is required only when referrer is "Other"
  otherReferrer: yup.string()
    .notRequired()
    .when('referrer', {
      is: (val) => val === 'OTHER',
      then: yup.string().required('referral-channel-other.required'),
      otherwise: yup.string().notRequired()
    }),

  // ---- Additional Files ----

  photos: yup.mixed()
    .test('fileExists', "photo.required", fileSet => fileSet.length > 0)
    .test('fileSize', "photo.too-large", fileSet => isFileSetWithinPermissibleSize(fileSet, MAX_FILE_SIZE)),

  resumeFiles: yup.mixed()
    .test('fileSize', "resume.too-large", fileSet => fileSet.length === 0 || (fileSet.length > 0 && isFileSetWithinPermissibleSize(fileSet, MAX_FILE_SIZE))),

});
