/**
 * new-volunteer-capture-success.js - New volunteer capture form successfully posted notification.
 *
 * @author Alex Symeonidis
 */
import React from "react"
import {useTranslation} from "react-i18next";

const NewVolunteerCaptureSuccess = () => {

  const { t } = useTranslation("new-volunteer-capture");

  return <div className={"container"}>
    <h3>{t('new-volunteer-capture.success.title')}</h3>
    <div>
      <p>{t('new-volunteer-capture.success.body')}</p>
    </div>
    <div>
      <p>{t('new-volunteer-capture.success.footer')}</p>
    </div>
  </div>
}

export default NewVolunteerCaptureSuccess;