/**
 * FormLayout.js - Form layout. This is used to build the static pages that
 * contain a single form.
 *
 * @author Alex Symeonidis
 */
import React from "react"
import PrimaryMenu from "../components/PrimaryMenu";
import { withTrans } from "../i18n/withTrans";

function FormLayout({ children }) {
  return <div className={"container"}>
    <PrimaryMenu/>
    { children }
  </div>
}

export default withTrans(FormLayout);