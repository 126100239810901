/**
 * new-volunteer-capture.js - New volunteer capture form page.
 *
 * @author Alex Symeonidis
 */
import React, { useState } from "react"
import NewVolunteerCaptureForm from "../forms/new-volunteer-capture/new-volunteer-capture-form";
import NewVolunteerCaptureSuccess from "../forms/new-volunteer-capture/new-volunteer-capture-success";
import LanguageMenu from "../components/LanguageMenu";
import FormLayout from "../layouts/FormLayout";

export function NewVolunteerCapture() {

  const [state, setState] = useState({
    isFormSuccessfullySubmitted: false
  });

  return (
    <FormLayout>
      <div className={"float-right"}>
        <LanguageMenu/>
      </div>
      {
        !state.isFormSuccessfullySubmitted &&
        <NewVolunteerCaptureForm
          onSubmitCompleted={() => {setState({...state, isFormSuccessfullySubmitted: true})}}/>
      }
      {
        state.isFormSuccessfullySubmitted &&
        <NewVolunteerCaptureSuccess/>
      }
    </FormLayout>
  );

}

export default NewVolunteerCapture;